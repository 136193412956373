import { GraphQLClient } from 'graphql-request';
import { TOKEN } from './constants';
const endpoint = 'https://api.my.yojji.io/admin/graphql/';
const token = localStorage.getItem(TOKEN);

export const client = new GraphQLClient(endpoint, {
  headers: {
    authorization: token ? `Token ${token}` : "",
  },
});
