import React from "react";

const LabeledInput = ({ label, ...rest }: any) => {
  return (
    <div className="flex flex-col mb-2">
      <label className="mb-1 text-xs">{label}</label>
      <input
        {...rest}
        className="rounded py-1 px-2 border border-indigo-200"
        onChange={(e) => rest.onChange(e.target.value)}
      />
    </div>
  );
};

export default LabeledInput;
