import React, { useEffect, useState } from "react";
import Column from "./components/Column";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useEmployees } from "./hooks/useEmployees";

import { TOKEN } from "./constants";
import { randomNumber } from "./utils/randomNumber";
import { useLocalStorage } from "./hooks/useLocalStorage";
import LabeledInput from "./components/LabeledInput";

function normalizeAssigment(ass) {
  const clone = {
    rateType: "HOUR",
  } as any;
  if (ass.rateType === "MONTH") {
    clone.rate = Math.round(+ass.rate / 168) as any;
  } else if (ass.rateType === "DAY") {
    clone.rate = Math.round(+ass.rate / 8) as any;
  } else {
    clone.rate = +ass.rate as any;
  }
  return clone;
}

const tokenInUse = localStorage.getItem(TOKEN);

function App() {
  const [token, setToken] = useState(localStorage.getItem(TOKEN));
  const submitToken = (e) => {
    localStorage.setItem(TOKEN, token);
    window.location.reload();
  };

  const { data } = useEmployees();
  const initialColumns = {
    all: {
      id: "all",
      name: "All",
      list: [],
    },
  };
  const [columns, setColumns] = useLocalStorage("columnsData", initialColumns);
  const [overHeadPrice, setOverHeadPrice] = useLocalStorage(
    "overHeadPrice",
    1000,
    parseFloat
  );
  const [overHeadPercent, setOverHeadPercent] = useLocalStorage(
    "overHeadPercent",
    1.15,
  );
  const [desiredProfitPercent, setDesiredProfitPercent] = useLocalStorage(
    "desiredProfitPercent",
    0.2,
    parseFloat
  );

  useEffect(() => {
    if (!data) return;
    const allList = data.map((item) => {
      let latestContract = item.contracts[0];
      item.contracts.forEach((c) => {
        if (new Date(c.dateFrom) > new Date(latestContract.dateFrom)) {
          latestContract = c;
        }
      });

      let latestAssignment = { rate: 0 };
      item.assignments.forEach((a) => {
        if (
          !a.dateTo ||
          (new Date(a.dateTo) > new Date() && new Date(a.dateFrom) < new Date())
        ) {
          latestAssignment = normalizeAssigment(a);
        }
      });

      return {
        name: item.user.lastName + " " + item.user.firstName,
        dbSalary: +latestContract?.salary,
        dbRate: latestAssignment ? latestAssignment.rate : null,
        id: item.id,
        draggableId: randomNumber(),
      };
    });

    function updateColumns(columns) {
      const clone = { ...columns };
      Object.keys(clone).forEach((key) => {
        clone[key].list = clone[key].list.map((item) => {
          const dbItem = allList.find((i) => i.id === item.id);
          return {
            ...item,
            name: dbItem.name,
            dbSalary: dbItem.dbSalary,
            dbRate: dbItem.dbRate,
          };
        });
      });

      return clone;
    }

    setColumns((columns) => ({
      ...updateColumns(columns),
      all: {
        id: "all",
        name: "All",
        list: allList,
      },
    }));
  }, [data]);

  const onDragEnd = (dropResult: DropResult) => {
    console.log(dropResult);
    const { source, destination, draggableId } = dropResult;
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end variables
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    if (end.id === "all") return null;

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Then insert the item at the right location
      newList.splice(
        destination.index,
        0,
        start.list.find((el) => el.draggableId === draggableId)
      );

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        name: start.name,
        list: newList,
      };

      // Update the state
      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      // const newStartList = start.list.filter(
      //   (_: any, idx: number) => idx !== source.index
      // );

      // Create a new start column
      // const newStartCol = {
      //   id: start.id,
      //   list: newStartList,
      //   name: start.name,
      // };

      // Make a new end list array
      const newEndList = end.list;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, {
        ...start.list.find((el) => el.draggableId === draggableId),
        draggableId: randomNumber(),
      });

      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList,
      };

      // Update the state
      setColumns((state) => ({
        ...state,
        [start.id]: start,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };

  const handleAddProject = () => {
    const name = "new_" + Math.random().toString(36).substr(2, 9);
    const newProject = {
      id: name,
      list: [],
      name: name,
    };
    setColumns((state) => ({ ...state, [newProject.id]: newProject }));
  };

  const [globalSettingsExpanded, setglobalSettingsExpanded] = useLocalStorage(
    "globalSettingsExpanded",
    true
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex justify-between px-4 py-2 m-2 rounded shadow-xl">
        <div>
          <h1 className="text-xl font-bold mb-4">Global settings</h1>
          {globalSettingsExpanded && (
            <div>
              <div className="flex items-end">
                <LabeledInput
                  label="Token"
                  type="text"
                  className="border py-1"
                  value={token}
                  onChange={setToken}
                />
                <button
                  onClick={submitToken}
                  className="bg-indigo-200 px-2 py-1 rounded mb-2"
                >
                  set token
                </button>
              </div>

              <LabeledInput
                label="Overhead price:"
                type="text"
                className="border py-1"
                value={overHeadPrice}
                onChange={setOverHeadPrice}
              />
              <LabeledInput
                label="Overhead percent 12312:"
                type="text"
                className="border py-1"
                value={overHeadPercent}
                onChange={setOverHeadPercent}
              />
              <LabeledInput
                label="Desired profit percent"
                type="text"
                className="border py-1"
                value={desiredProfitPercent}
                onChange={setDesiredProfitPercent}
              />
            </div>
          )}
        </div>

        <button
          onClick={() => setglobalSettingsExpanded((s) => !s)}
          className="text-2xl"
        >
          {globalSettingsExpanded ? "⬆️" : "⬇️"}
        </button>
      </div>

      {tokenInUse && (
        <>
          <div className="p-2">
            <button
              className="bg-indigo-200 py-1 px-2 rounded mr-2"
              onClick={handleAddProject}
            >
              add project
            </button>

            <button
              className="bg-indigo-200  py-1 px-2 rounded"
              onClick={() => {
                localStorage.removeItem("columnsData");
                window.location.reload();
              }}
            >
              global reset
            </button>
          </div>

          <div className="mx-auto flex overflow-x-auto">
            {Object.values(columns)
              .sort((a: any, b) => (a.id !== "all" ? 1 : -1))
              .map((col: any) => (
                <Column
                  col={col}
                  key={col.id}
                  onChange={(col) =>
                    setColumns((s) => ({ ...s, [col.id]: col }))
                  }
                  overHeadPrice={overHeadPrice}
                  overHeadPercent={overHeadPercent}
                  desiredProfitPercent={desiredProfitPercent}
                />
              ))}
          </div>
        </>
      )}
    </DragDropContext>
  );
}

export default App;
