import { useQuery } from 'react-query';
import { client } from '../client';
import { gql } from 'graphql-request';
// import data from '../data.json';

const query = gql`
  {
    employees(isActive: true) {
      id
      contracts {
        dateFrom
        salary
        currency
      }
      user {
        firstName
        lastName
      }
      payslipSet {
        id
        createdAt
        updatedAt
        status
        amount
        currency
      }
      assignments {
        dateTo
        dateFrom
        rate
        rateType
      }
      position {
        title
      }
    }
  }
`;

export const useEmployees = (opts?: any) => {
  return useQuery(
    'employees',
    async () => {
      const data = await client.request(query).catch(console.log);
      return data ? data.employees : [];
    },
    opts,
  );

};
