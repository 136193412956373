import React, { useState } from "react";
import Item, { itemProfitPercent, roundDecimals } from "./Item";
import { Droppable } from "react-beautiful-dnd";
import CloseButton from "./CloseButton";
import LabeledInput from "./LabeledInput";

interface ColumnProps {
  col: {
    id: string;
    list: any[];
  };
}

const Column: React.FC<any> = ({
  col,
  onChange,
  overHeadPrice,
  overHeadPercent,
  desiredProfitPercent,
}) => {
  const { list, id, name } = col;
  const [localSearch, setLocalSearch] = useState("");
  console.log(list)
  return (
    <Droppable droppableId={id}>
      {(provided) => {
        const sumSalary = list.reduce(
          (a, c) => (a + (c.customSalary ? +c.customSalary : c.dbSalary)),
          0
        );
        const sumRevenueHour = list.reduce((a, c) => a + (c.customRate ? +c.customRate : c.dbRate), 0);
        console.log({ sumSalary, sumRevenueHour });
        const avgProfit =
          1 -
          (sumSalary * overHeadPercent + overHeadPrice * list.length) /
            (sumRevenueHour * 168);
        return (
          <div className="p-2 flex flex-col mt-2">
            <input
              className="mb-2 font-bold text-lg"
              value={name}
              onChange={(e) => onChange({ id, name: e.target.value, list })}
              disabled={id === "all"}
            />

            <div className="shadow p-2 rounded">
              <div>Project summary</div>
              <div>Total salary: {Math.round(sumSalary)}</div>
              <div
                className={
                  !list.length
                    ? ""
                    : avgProfit >= desiredProfitPercent
                    ? "text-green-500"
                    : "text-red-500"
                }
              >
                Avg project profit percent: {roundDecimals(avgProfit, 2)}
              </div>
            </div>

            <div className="py-2 pt-6 flex items-center justify-between">
              <LabeledInput
                label={"Column search: "}
                value={localSearch}
                onChange={setLocalSearch}
              />
              <CloseButton onClick={() => setLocalSearch("")} />
            </div>

            <div
              className="bg-[#ddd] rounded p-4 flex flex-col flex-grow mt-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {list
                .filter((item) =>
                  item.name.toLowerCase().includes(localSearch.toLowerCase())
                )
                .map((item, index) => (
                  <Item
                    onDelete={() => {
                      onChange({
                        id,
                        name,
                        list: list.filter(
                          (el) => el.draggableId !== item.draggableId
                        ),
                      });
                    }}
                    col={col}
                    key={item.name}
                    item={item}
                    index={index}
                    onChange={(item) => {
                      const clone = [...list];
                      clone[index] = item;
                      onChange({ id, name, list: clone });
                    }}
                    overHeadPrice={overHeadPrice}
                    overHeadPercent={overHeadPercent}
                    desiredProfitPercent={desiredProfitPercent}
                  />
                ))}
              {provided.placeholder}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export default Column;
