import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { beautifyPercent } from "../utils/beautifyPercent";
import CloseButton from "./CloseButton";

export const roundDecimals = (num, decimals) => {
  if (num === Infinity || num === -Infinity) {
    return 0;
  }
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const itemProfitPercent = (item, overHeadPrice, overHeadPercent) => {
  const salary = item.customSalary ? +item.customSalary : item.dbSalary;
  const profit = roundDecimals(
    1 -
      (salary * +overHeadPercent + +overHeadPrice) /
        ((item.customRate || item.dbRate) * 168),
    2
  );
  return profit;
};

const Item: React.FC<any> = ({
  item,
  index,
  onChange,
  col,
  onDelete,
  overHeadPercent,
  overHeadPrice,
  desiredProfitPercent,
}) => {
  return (
    <Draggable draggableId={item.draggableId} index={index}>
      {(provided) => (
        <div
          className="bg-[#eee] rounded py-1 px-2 mt-2 hover:bg-[#fff] flex"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div>
            <div className="flex">
              <div className="w-[150px] truncate whitespace-nowrap mr-2">
                {item.name}
              </div>
              <div className="w-[50px] mr-2">
                {item.customSalary || item.dbSalary}
                {!!item.customSalary && <span className="text-red-500">*</span>}
              </div>
              <div className="w-[30px] mr-2">
                {item.customRate || item.dbRate}
                {!!item.customRate && <span className="text-red-500">*</span>}
              </div>
              <div className="w-[70px] mr-2">
                {beautifyPercent(
                  itemProfitPercent(item, overHeadPrice, overHeadPercent)
                )}
              </div>
              <button
                className="mr-2 bg-gray-200 rounded px-2"
                onClick={() => {
                  onChange({ ...item, expanded: !item.expanded });
                }}
              >
                {item.expanded ? "☝️" : "👇"}
              </button>

              {col.id !== "all" && <CloseButton onClick={onDelete} />}
            </div>

            {item.expanded && (
              <div className="mt-4">
                <div className="">Current salary: {item.dbSalary}</div>
                <div className="">Current rate (h): {item.dbRate}</div>
                <div className="mb-2">
                  Current rate (m): {roundDecimals(item.dbRate * 168, 2)}
                </div>
                <div className="flex mb-2">
                  Custom salary:
                  <input
                    value={item.customSalary || ""}
                    placeholder="Custom salary, $"
                    className=" ml-2"
                    onChange={(e) => {
                      onChange({ ...item, customSalary: +e.target.value });
                    }}
                  />
                </div>
                <div className="flex mb-2">
                  Custom rate (h):
                  <input
                    value={item.customRate || ""}
                    placeholder="Custom rate, $"
                    className=" ml-2"
                    onChange={(e) => {
                      onChange({ ...item, customRate: +e.target.value });
                    }}
                  />
                </div>

                <div>
                  Salary to make {desiredProfitPercent * 100}%:{" "}
                  {roundDecimals(
                    (+item.dbRate * 168 * (1 - +desiredProfitPercent) -
                      +overHeadPrice) /
                      +overHeadPercent,
                    2
                  )}
                </div>
                <div>
                  Price to make {desiredProfitPercent * 100}%:{" "}
                  {roundDecimals(
                    (item.customSalary ||
                      item.dbSalary * +overHeadPercent + +overHeadPrice) /
                      (1 - +desiredProfitPercent),
                    2
                  )}{" "}
                  (
                  {roundDecimals(
                    (item.customSalary ||
                      item.dbSalary * +overHeadPercent + +overHeadPrice) /
                      (1 - +desiredProfitPercent) /
                      168,
                    2
                  )}
                  )
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Item;
